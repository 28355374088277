export default ({ app }, inject) => {
    
    const getOrderedList = (list, allDocuments, modelClass, count = undefined) => {
        
        if (!Array.isArray(list)) {
            list = Object.values(list);
        }

        let arr = []; 
        let maxSalesCount = 0;
        let maxConversionRate = 0;
        let maxEpc = 0;

        list.forEach(l => {
            const document = Object.assign({}, allDocuments[l.id]);
            document.model = new modelClass(document);
    
            maxEpc = document.model._core.sales_stats.epc > maxEpc ? document.model._core.sales_stats.epc : maxEpc;
            maxSalesCount = document.model._core.sales_stats.sales > maxSalesCount ? document.model._core.sales_stats.sales : maxSalesCount;
            maxConversionRate = document.model._core.sales_stats.conversion_rate > maxConversionRate ? document.model._core.sales_stats.conversion_rate : maxConversionRate;
    
            arr.push(document);
        });

        arr.forEach(document => {
    
            if (document.model._core.sales_stats.sales === 0) {
                document.order = document.model._core.sales_stats.clicks * -1;
            }
            else if (document.model._core.sales_stats.sales < 5) {
                document.order =
                    document.model._core.sales_stats.epc / maxEpc +
                    document.model._core.sales_stats.sales / maxSalesCount +
                    document.model._core.sales_stats.conversion_rate / maxConversionRate;
            }
            else {
                document.order =
                    (8 * (document.model._core.sales_stats.epc / maxEpc)) +
                    document.model._core.sales_stats.sales / maxSalesCount +
                    document.model._core.sales_stats.conversion_rate / maxConversionRate;
            }
        });
    
        return arr
            .sort((a, b) => b.order - a.order)
            .slice(0, count || arr.length);
    };

    inject('getOrderedList', getOrderedList);
};